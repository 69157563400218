import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref, computed } from 'vue'
import * as Sentry from '@sentry/vue'
import type { TokenCredential } from '@azure/identity'

export type UserProfile = {
  _id: string
  Forename?: string
  Surname?: string
  UserRole?: string
  UserLocation?: string
  ClientCode?: string
  mail?: string
  apps: string[]
} & Record<string, unknown>

type ApiType = {
  current: string
  available: string[]
}
export type UserData = {
  email: string
  profile: UserProfile
  aad: TokenCredential
  apps: string[]
  appConfig: object
  api: ApiType
}

export const useUserSession = defineStore('userSession', () => {
  const user = ref<Partial<UserData>>()
  const loading = ref(true)
  const isAdmin = computed(
    () => user.value?.profile?.UserRole === 'Admin' || user.value?.profile?.UserRole === 'SuperAdmin',
  )

  const isLoggedIn = computed(() => !!user.value)

  function setUser(newUser: Partial<UserData>) {
    if (!newUser?.api || !newUser?.aad) {
      Sentry.captureException(new Error('User data is missing'))
      console.error('User data is missing')
      return
    }

    user.value = {
      ...newUser,
      // Use the mail attribute if available (this is the correct email for AAD guest users)
      email: newUser.aad.mail || newUser.aad.userPrincipalName,
    }

    // Set user context in Sentry
    Sentry.setUser({
      email: newUser.aad.userPrincipalName,
      clientCode: newUser.api.current,
    })
  }

  function setLoading(newLoading: boolean) {
    loading.value = newLoading
  }

  async function logoutUser() {
    user.value = undefined
  }

  return {
    user,
    isLoggedIn,
    loading,
    logoutUser,
    setUser,
    setLoading,
    isAdmin,
  } as const
})

/**
 * Pinia supports Hot Module replacement so you can edit your stores and
 * interact with them directly in your app without reloading the page.
 *
 * @see https://pinia.esm.dev/cookbook/hot-module-replacement.html
 * @see https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserSession, import.meta.hot))
}
